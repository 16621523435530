html{
    background-color: grey;
    font-family: "Urbanist",sans-serif;
    font-weight: 500;
    text-rendering: optimizeLegibility!important;
}

a:link,a:visited,a:hover,a:active{
    text-decoration: none;
}

::-webkit-scrollbar {
    display: none;
}

@font-face {
    font-family: 'Urbanist';
    src: url(../fonts/Urbanist.ttf);
}

:root {
    --github: rgb(0,0,0);
    --githubA: rgba(0,0,0,0.6);
    --blog: rgba(255, 166, 0);
    --blogA: rgba(255, 166, 0, 0.6);
    --switch: rgba(228,0,15);
    --switchA: rgba(228,0,15, 0.6);
    --mastodonPurple: rgba(93, 80, 231);
    --mastodonPurpleA: rgba(93, 80, 231, 0.6);
    --JGURed: rgba(193,0,42);
    --JGURedA: rgba(193,0,42,0.6);
    --scienceGreen: rgba(122, 184, 22);
    --scienceGreenA: rgba(122, 184, 22, 0.6);
}

.rainbow{
    background-image: -webkit-gradient( linear, left top, right top, color-stop(0, red), color-stop(0.2, orange), color-stop(0.4, yellow), color-stop(0.6, green), color-stop(0.8, blue), color-stop(1, purple) );
    background-image: gradient( linear, left top, right top, color-stop(0, red), color-stop(0.2, orange), color-stop(0.4, yellow), color-stop(0.6, green), color-stop(0.8, blue), color-stop(1, purple) );
    -webkit-background-clip: text;
    color: white;
    background-clip: text;
    transition: color 1s
}

.rainbow:hover{
    color: transparent
}
